.newsletter {

    .section-inner {
        padding-bottom: 64px;
    }

    .section-paragraph {
        margin-bottom: 32px;
    }
}

.newsletter-form {
    max-width: 550px;
    margin: 0 auto;
}

@include media( '>medium' ) {

    .newsletter {

        .section-inner {
            padding-bottom: 120px;
        }

        .section-paragraph {
            margin-bottom: 40px;
            padding-left: 72px;
            padding-right: 72px;
        }
    }
}

.site-footer {
    position: relative;
    @include font-size(8, mobile, true, true, true);
    @if ( get-font-size(8, desktop) != get-font-size(8, mobile) ) {
        @include media( '>medium' ) {
            @include font-size(8, desktop, true, true, true);
        }
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 259px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTQ0MCIgaGVpZ2h0PSIyMTciPiAgPGRlZnM+ICAgIDxwYXRoIGQ9Ik0yNjEuNyAyODYuNjgyYzQyMS40NzcgMCAxNDg4Ljk3NyAxNzYuMDc4IDE0ODguOTc3IDM5LjIzNHM0NzguNzU0LTI0OC4wNDMgNTcuMjc4LTI0OC4wNDNjLTE5OC43NDYgMC00NjguNTY2IDMuNTU1LTcxOS45NTctMTEuMjNDODA2LjI3MSA1MC4wNzYgNTQxLjkgMjcuMjI3IDM0NC45NzcgNTIuNDM4LTI3LjY2NSAxMDAuMTQ1LTE1OS43NzYgMjg2LjY4MyAyNjEuNyAyODYuNjgzeiIgaWQ9ImEiLz4gIDwvZGVmcz4gIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yODUgLTQxKSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgPG1hc2sgaWQ9ImIiIGZpbGw9IiNmZmYiPiAgICAgIDx1c2UgeGxpbms6aHJlZj0iI2EiLz4gICAgPC9tYXNrPiAgICA8dXNlIGZpbGw9IiNmZmExOTQiIHhsaW5rOmhyZWY9IiNhIiBzdHlsZT0iJiMxMDsiLz4gICAgPHBhdGggZD0iTTI0MC43MjcgMzI2LjM5NWM0MjEuNDc2IDAgOTcyLjc0NCAxNzkuNzg3IDk3Mi43NDQgNDIuOTQzczEyOS4yODYtMTc1Ljc0OC0yOTIuMTktMTc1Ljc0OC0yNTYuMzE0LTIyNi40OTQtMjU2LjMxNC04OS42NWMwIDEzNi44NDMtODQ1LjcxNyAyMjIuNDU1LTQyNC4yNCAyMjIuNDU1eiIgZmlsbD0iI0ZEODg3OSIgbWFzaz0idXJsKCNiKSIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIDEgMTM1NS4xNjIgMCkiLz4gICAgPHBhdGggZD0iTTQ0NS43IDM4Ny42ODJjNDIxLjQ3NyAwIDk3Mi43NDUgMTc5Ljc4NyA5NzIuNzQ1IDQyLjk0M3M1NzkuOTQxLTI1NS4yNDYgMTU4LjQ2NS0yNTUuMjQ2LTcwNi45NjktMTQ2Ljk5Ni03MDYuOTY5LTEwLjE1MmMwIDEzNi44NDMtODQ1LjcxNyAyMjIuNDU1LTQyNC4yNCAyMjIuNDU1eiIgZmlsbD0iI2Y0ODQ3MCIgbWFzaz0idXJsKCNiKSIvPiAgPC9nPjwvc3ZnPg==);
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
    }

    a {
        @include anchor-aspect(footer);
    }
}

.site-footer-inner {
    position: relative; /* To display all elements above the background color */
    display: flex;
    flex-wrap: wrap;
    padding-top: 40px;
    padding-bottom: 40px;
}

.footer-brand,
.footer-links,
.footer-social-links,
.footer-copyright {
    flex: none;
    width: 100%;
    display: inline-flex;
    justify-content: center;
}

.footer-brand,
.footer-links,
.footer-social-links {
    margin-bottom: 24px;
}

.footer-links,
.footer-social-links {

    li {

        + li {
            margin-left: 16px;
        }
    }
}

.footer-social-links {

    align-items: flex-end;

    li {
        display: inline-flex;

        a {
            padding: 8px;
        }
    }
}

@include media( '>medium' ) {

    .site-footer {

        &::before {
            height: 202px;
        }
    }

    .site-footer-inner {
        justify-content: space-between;
    }

    .footer-brand,
    .footer-links,
    .footer-social-links,
    .footer-copyright {
        flex: 50%;
    }

    .footer-brand,
    .footer-copyright {
        justify-content: flex-start;
    }

    .footer-links,
    .footer-social-links {
        justify-content: flex-end;
    }

    .footer-links {
        order: 1;
        margin-bottom: 0;
    }
}

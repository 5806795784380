.button {
    display: inline-flex;
    @include font-size(9, mobile, true, false, true);
    @if ( get-font-size(9, desktop) != get-font-size(9, mobile) ) {
        @include media( '>medium' ) {
            @include font-size(9, desktop, true, false, true);
        }
    }
    @include font-weight(bold);
    line-height: 16px;
    text-transform: uppercase;
    text-decoration: none !important;
    background-color: color(bg, 1);
    color: color(primary, 1) !important;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    justify-content: center;
    padding: 16px 32px;
	  height: 48px;
    text-align: center;
    white-space: nowrap;
    width: 100%;

    &:active {
        outline: 0;
    }

    &::before {
        border-radius: 2px;
    }
}

.button-shadow {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: 0 8px 24px rgba(color(typography, 1), .12);
        mix-blend-mode: multiply;
        transition: box-shadow .15s ease;
    }

    &:hover {

        &::before {
            box-shadow: 0 8px 24px rgba(color(typography, 1), .25);
        }
    }
}

.button-sm {
    padding: 8px 24px;
	height: 32px;

    &.button-shadow {

        &::before {
            box-shadow: 0 4px 16px rgba(color(typography, 1), .12);
        }

        &:hover {

            &::before {
                box-shadow: 0 4px 16px rgba(color(typography, 1), .25);
            }
        }
    }
}

.button-primary {
    color: color(typography, 1i) !important;
    transition: background .15s ease;
}

.button-primary {
    background: mix(color(additional, 4), color(additional, 5));
    background: linear-gradient(65deg, color(additional, 4) 0, color(additional, 5) 100%);

    &:hover {
        background: lighten(mix(color(additional, 4), color(additional, 5)), 1%);
        background: linear-gradient(65deg, lighten(color(additional, 4), 1%) 0, lighten(color(additional, 5), 1%) 100%);
    }
}

.button-block {
    display: flex;
}
